<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-19 21:06:15
 * @LastEditTime: 2020-05-06 13:30:41
 -->
<template>
	<div v-loading="loading" class="app-container">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="年份">
				<el-date-picker
					type="year"
					v-model="searchForm.year"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
					:clearable="false"
				></el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList" icon="el-icon-search">查询</el-button>
			<el-button type="primary" size="small" @click="_export" style="float: right" icon="el-icon-download">导出</el-button>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column align="center" fixed prop="reason" label="离职原因" show-overflow-tooltip width="100"></el-table-column>
			<el-table-column
				v-for="(col, i) in columns"
				:prop="col.prop"
				:key="i"
				:label="col.label"
				align="center"
				show-overflow-tooltip
			></el-table-column>
			<el-table-column
				fixed="right"
				prop="reasonProportion"
				label="离职原因占比"
				show-overflow-tooltip
				width="150"
				align="center"
			></el-table-column>
		</el-table>
	</div>
</template>
<script>
import { analysisSummary } from '@/api/overview';
export default {
	data() {
		return {
			loading: false,
			list: [],
			searchForm: {
				year: ''
			},
			columns: [
				{ label: '1月', prop: 'january' },
				{ label: '2月', prop: 'february' },
				{ label: '3月', prop: 'march' },
				{ label: '4月', prop: 'april' },
				{ label: '5月', prop: 'may' },
				{ label: '6月', prop: 'june' },
				{ label: '7月', prop: 'july' },
				{ label: '8月', prop: 'august' },
				{ label: '9月', prop: 'september' },
				{ label: '10月', prop: 'october' },
				{ label: '11月', prop: 'november' },
				{ label: '12月', prop: 'december' }
			]
		};
	},
	methods: {
		_getList() {
			this.loading = true;
			analysisSummary(this.searchForm).then((res) => {
				this.list = res;
				this.loading = false;
			});
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/overview/quit/Analysis/summary/export?params=${outStr}`;

			window.open(document.location.origin + url);
		}
	},
	mounted() {
		const now = new Date();

		this.searchForm.year = String(this.$route.query.year ? this.$route.query.year : now.getFullYear());
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
